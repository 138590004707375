@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'
// app global css in Sass form

.light-section
  background-color: $light-section-background
  color: $light-section-color

.dark-section
  color: $dark-section-color
  background-color: $dark-section-background
